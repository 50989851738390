<template>
  <div>
    <div class="text-start px-5 py-2">
      <div class="pb-3 m-3">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <h4>
              {{
                $t("borrow.select_number", selectedAssetCount, {
                  count: selectedAssetCount,
                })
              }}
            </h4>
            <div class="d-flex my-2 overflow-auto align-items-center">
              <button
                class="btn-filled secondary-btn d-flex mx-2 my-1 flex-shrink-0"
                @click="$router.back()"
              >
                <p>{{ $t("borrow.cancel") }}</p>
              </button>
              <button
                class="btn-filled primary-btn d-flex mx-2 my-1 flex-shrink-0"
                :disabled="selectedAssetCount <= 0"
                @click="confirmSelectAsset"
              >
                <p>{{ $t("borrow.confirm_select") }}</p>
              </button>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex align-items-center">
              <div class="position-relative m-2" style="height: fit-content">
                <input
                  v-model="filter.text"
                  v-debounce:700ms="loadAsset"
                  type="text"
                  :placeholder="$t('borrow.search')"
                  class="form-control"
                  style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
                />
                <img
                  class="position-absolute top-50 translate-middle-y"
                  style="left: 5px"
                  src="../../assets/search-white.svg"
                  width="20px"
                />
              </div>
              <button
                class="filter flex-shrink-0"
                type="button"
                id="button-addon2"
                @click="filterModal = true"
              >
                <img src="@/assets/filter-white.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <add-borrow-table
        ref="BorrowTableRef"
        :assetData="tableData"
        :otherColumn="otherColumn"
        :page="currentPage"
        :perPage="perPage"
        @selected-count="setSelectedCount"
        @interface="getBorrowTableInterface"
      ></add-borrow-table>
      <div class="d-flex justify-content-center mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          @input="loadAsset"
        ></b-pagination>
      </div>
    </div>
    <base-dialog
      :showModal="addAssetModal"
      @confirm-action="addNewBorrowAssets"
      @cancel-action="addAssetModal = false"
    >
      <template
        ><div>
          <img
            src="../../assets/BorrowReturn/check_confirm.svg"
            alt=""
            width="22px"
            class="mx-auto d-block"
          />
          <p class="my-3 text-center">
            {{ $t("borrow.confirm_add_borrow_dialog") }}
          </p>
        </div></template
      >
      <template #after>
        <div class="my-3 p-3" style="max-height: 400px; overflow: auto;">
          <div class="d-flex justify-content-between">
            <p>{{ $t("borrow.asset") }}</p>
            <p>{{ selectedAsset.length }} {{ $t("borrow.list") }}</p>
          </div>
          <asset-card
            v-for="asset in selectedAsset.values()"
            :key="asset.asset_id"
            :assetId="asset.asset_id"
            :assetName="asset.name"
            :assetQR="asset.id_fixed_asset"
            :assetImageUrl="asset.asset_image"
            @action-click="removeSelectedAsset"
          ></asset-card>
        </div>
      </template>
    </base-dialog>
    <asset-filter
      :showModal="filterModal"
      @confirm-action="handleFilter"
      @cancel-action="handleClear"
      @hide-modal="filterModal = false"
    ></asset-filter>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import { baseUrl, borrowUrl } from "../../util/backend";

import BaseDialog from "../../components/BaseDialog.vue";
import AssetCard from "../../components/BorrowReturn/AssetCard.vue";
import AddBorrowTable from "../../components/Table/BorrowReturn/AddBorrowTable.vue";
import AssetFilter from "../../components/BorrowReturn/AssetFilter.vue";

export default {
  components: {
    BaseDialog,
    AssetCard,
    AddBorrowTable,
    AssetFilter,
  },
  data() {
    return {
      BorrowListTableFunction: {
        getSelectedRowData: () => {},
        showLoading: () => {},
        hideLoading: () => {},
      },
      id_company: null,
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      currentPage: 1,
      perPage: 10,
      filterModal: false,
      filter: {
        text: null,
      },
      filterOptions: null,
      tableData: null,
      otherColumn: [],
      totalRows: 0,

      addAssetModal: false,
      selectedAsset: null,
      selectedAssetCount: 0,
    };
  },
  methods: {
    handleFilter(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.loadAsset();
    },
    handleClear(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.filter.text = "";
      this.loadAsset();
    },
    async addNewBorrowAssets() {
      if (this.selectedAsset?.length <= 0) return;

      const asset_ids = this.selectedAsset?.map((asset) => asset.asset_id);

      try {
        const res = await axios.put(
          `${borrowUrl()}asset/company/id/${this.id_company}`,
          { asset_ids },
          authHeader()
        );

        Swal.fire({
          icon: "success",
          title: this.$t("borrow.add_assets_success"),
          showConfirmButton: false,
          timer: 2000,
          onDestroy: () => {
            this.addAssetModal = false;
            this.selectedAsset = null;
            this.selectedAssetCount = 0;

            setTimeout(() => {
              this.$router.push({
                name: "borrow-list",
              });
            }, 500);
          },
        });
      } catch (err) {
        // Error Handling needed
        console.error(err);
      }
    },
    getBorrowTableInterface(childInterface) {
      this.BorrowListTableFunction = childInterface;
    },
    setSelectedCount(value) {
      this.selectedAssetCount = value;
    },
    handleStatusFilter(status) {
      this.filter.status = status;
    },
    async loadAsset() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.id_company = id_company;
      this.BorrowListTableFunction.showLoading();
      const response = await axios.get(
        `${baseUrl()}asset/allAssetCanBorrow/${id_company}`,
        {
          params: {
            ...this.filter,
            ...this.filterOptions,
            is_borrow_return: false,
            page: this.currentPage,
            limit: this.perPage,
          },
          ...authHeader(),
        }
      );

      this.tableData = response.data.data.assets;
      this.otherColumn = response.data.other_column;
      this.totalRows = response.data.data.count;
      this.BorrowListTableFunction.hideLoading();
    },
    getSelectedAsset() {
      return this.BorrowListTableFunction.getSelectedRowData();
    },
    confirmSelectAsset() {
      this.selectedAsset = Array.from(
        this.getSelectedAsset(),
        ([name, value]) => value
      );
      this.addAssetModal = true;
    },
    removeSelectedAsset(assetId) {
      const index = this.selectedAsset.findIndex(
        (asset) => asset.asset_id === assetId
      );
      this.selectedAsset.splice(index, 1);
    },
  },
  created() {
    this.loadAsset();
  },
};
</script>

<style lang="scss" scoped>
.btn-filled {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #fff;
    background-color: #007afe;
    border-color: #007afe;
  }
  &.secondary-btn {
    color: #fff;
    background-color: #818181;
    border-color: #818181;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default !important;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
    width: 40px;
  }
}
</style>
