<template>
  <div class="asset-card d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <img class="me-3" :src="assetImageUrl" alt="" />
      <div>
        <p>{{ assetName }}</p>
        <p style="color: #818181;">{{ assetQR }}</p>
      </div>
    </div>
    <span v-if="hasAction">
      <div
        class="cursor-pointer fw-bold"
        @click="handleActionClick"
        v-if="action === 'remove'"
      >
        &#10005;
      </div>
      <div
        class="cursor-pointer fw-bold"
        @click="handleActionClick"
        v-if="action === 'edit'"
      >
        <img
          style="width: 15px; height: 15px"
          src="../../assets/edit_pencil.svg"
          alt=""
        />
      </div>
    </span>
  </div>
</template>

<script>
export default {
  emits: ["action-click"],
  props: {
    hasAction: {
      type: Boolean,
      default: true,
    },
    action: {
      type: String,
      default: "remove",
    },
    assetId: {
      type: Number,
      required: true,
    },
    assetName: {
      type: String,
      required: true,
    },
    assetQR: {
      type: String,
      required: true,
    },
    assetImageUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleActionClick() {
      this.$emit("action-click", this.assetId);
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-card {
  padding: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: 1rem 0 0 0;
  text-align: left;
  img {
    width: 50px;
    height: 30px;
    object-fit: contain;
  }
}
</style>
